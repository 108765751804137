import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
//import { AngularFireDatabase} from '@angular/fire/database';
import { DatePipe } from '@angular/common';
//import { QRScanner } from '@ionic-native/qr-scanner/ngx';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';


import { Storage } from '@ionic/storage-angular';

//Native Plugins

//import { FCM } from '@ionic-native/fcm/ngx';
//import { PayPal } from '@ionic-native/paypal/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { Contacts, Contact, ContactFindOptions } from '@ionic-native/contacts/ngx';
import { FIREBASE_CONFIG } from './firebase.credentials';
import { IonicSelectableModule } from 'ionic-selectable';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';




@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
            IonicModule.forRoot(),
            AppRoutingModule, 
            HttpClientModule,
            AngularFireAuthModule, 
            AngularFireModule,
            AngularFireModule.initializeApp(FIREBASE_CONFIG),
            AngularFireStorageModule, AngularFireDatabaseModule,
            IonicSelectableModule
            ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },
               Network, SocialSharing, Camera, Contacts, Contact,BarcodeScanner, FirebaseMessaging,//FCM, 
              File,  Contact, DatePipe, Storage, InAppBrowser, FirebaseAuthentication, Device,AppVersion,Market
              //QRScanner, 
            ],
  bootstrap: [AppComponent],
})
export class AppModule { }
