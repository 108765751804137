//LIVE
export const FIREBASE_CONFIG={
    apiKey: "AIzaSyCBPDlEN6sRaFa6Ak6tGEdLrtde1cOIVAE",
    authDomain: "fishcoin-ec1e9.firebaseapp.com",
    databaseURL: "https://fishcoin-ec1e9.firebaseio.com",
    projectId: "fishcoin-ec1e9",
    storageBucket: "fishcoin-ec1e9.appspot.com",
    messagingSenderId: "152146299414",
    appId: "1:152146299414:web:21db34cae23c38b6478337",
};


// const firebaseConfig = {
//     apiKey: "AIzaSyCBPDlEN6sRaFa6Ak6tGEdLrtde1cOIVAE",
//     authDomain: "fishcoin-ec1e9.firebaseapp.com",
//     databaseURL: "https://fishcoin-ec1e9.firebaseio.com",
//     projectId: "fishcoin-ec1e9",
//     storageBucket: "fishcoin-ec1e9.appspot.com",
//     messagingSenderId: "152146299414",
//     appId: "1:152146299414:web:21db34cae23c38b6478337"
//   };

//CLIENT TEST
// export const FIREBASE_CONFIG={
//     apiKey: "AIzaSyA8R5M1bG-2m27ymsU_cYPcdG3kZTZ8JLA",
//     authDomain: "mfishapi.firebaseapp.com",
//     databaseURL: "https://mfishapi.firebaseio.com",
//     projectId: "mfishapi",
//     storageBucket: "mfishapi.appspot.com",
//     messagingSenderId: "552651257376",
// };

//DEVELOPER UAT TEST NET -- com.fishcoin.app
// export const FIREBASE_CONFIG={
//     apiKey: "AIzaSyCinkDvbmQ0DIB4SrJRfSNGXBaPMM1RUjE",
//     authDomain: "fishcoinuat.firebaseio.com",
//     databaseURL: "https://fishcoinuat.firebaseio.com",
//     projectId: "fishcoinuat",
//     storageBucket: "fishcoinuat.appspot.com",
//     messagingSenderId: "227438709982"
// };
// UAT TEST FOR mFarm and mPalm
// export const FIREBASE_CONFIG={
//     apiKey: "AIzaSyCbLml3nyVK6F9huEB2im9RZMMsMUDLNKo",
//     authDomain: "fishcoin-uat.firebaseapp.com",
//     databaseURL: "https://fishcoin-uat-default-rtdb.firebaseio.com",
//     projectId: "fishcoin-uat",
//     storageBucket: "fishcoin-uat.appspot.com",
//     messagingSenderId: "1046474668336",
//     appId: "1:1046474668336:web:dedb09a0d9aeda75f7b0f5",
//     measurementId: "G-39XYXXM41T"
//   };