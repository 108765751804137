export const environment = {
  production: true,
  // baseBlockchainURL: 'https://mfish-trace.co/api/',
  baseBlockchainURL: 'https://mfish-trace.co/api/',// WEB API
  // baseBlockchainURL: 'http://c7d63287044c.ngrok.io/', //MOBILE API
  stellarURL: 'https://stellarchain.io/tx/',
  transferToURL: 'https://gs-api.dtone.com/v1.1/',
  transferto_apikey: '748983ae-2195-457a-a3c0-b778210c3114',
  transferto_apisecret: '01fe526b-9573-4a41-9380-a98a51d25b60',
  baseCMXURL: 'https://mfish-trace.co/cmxAPI/',
  //  baseCMXURL:'http://52.163.83.160:3000/cmxAPI/',

  countryUrl: 'https://api.ipdata.co/?api-key=4dfedc2445a6a1ad370aa138410098bfe3b46b9bed0b1ed40fc9a8cf',
  accessTokenUrl: 'https://mfish-trace.co/cmxAPI/firebase/getAccessToken',
  versionCheckUrl: 'https://mfish-trace.co/cmxAPI/firebase/getLiveVersion',
  notificationUrl: 'https://fcm.googleapis.com/v1/projects/fishcoin-ec1e9/messages:send',
  appVersion: '1.0.8',
  localUrl: 'https://mfish-trace.co/'//set as DToneTestUrl url
};



// export const environment = {
//   production: true,
//   // baseBlockchainURL: 'http://52.163.83.160:7771/api/',
//   //  baseBlockchainURL: 'http://52.163.83.160:7771/api/',// WEB API
//    baseBlockchainURL: 'https://mfish-trace.co/api/',// WEB API //TEST
//  // baseBlockchainURL: 'http://c7d63287044c.ngrok.io/', //MOBILE API
//   stellarURL: 'https://stellarchain.io/tx/',
//   transferToURL: 'https://gs-api.dtone.com/v1.1/',
//   transferto_apikey: '748983ae-2195-457a-a3c0-b778210c3114',
//   transferto_apisecret: '01fe526b-9573-4a41-9380-a98a51d25b60',
//   baseCMXURL:'http://52.163.83.160:7772/cmxAPI/',
// //  baseCMXURL:'http://52.163.83.160:3000/cmxAPI/',
//   countryUrl:'https://api.ipdata.co/?api-key=4dfedc2445a6a1ad370aa138410098bfe3b46b9bed0b1ed40fc9a8cf',
//   accessTokenUrl:'http://52.163.83.160:7771/cmxAPI/firebase/getAccessToken',
//   versionCheckUrl:'http://52.163.83.160:7771/cmxAPI/firebase/getLiveVersion',
//   notificationUrl:'https://fcm.googleapis.com/v1/projects/fishcoin-ec1e9/messages:send',
//   appVersion : '1.0.8',
//   localUrl : 'http://52.163.83.160:7772/'//set as DToneTestUrl url

// };


//TEST LINK
// export const ENV = {
//   production: false,
//   baseBlockchainURL: 'http://52.163.83.160:1989/', //Test Link
//   stellarURL: 'http://testnet.stellarchain.io/tx/',
//   transferToURL: 'https://gs-api.dtone.com/v1.1/',
//   transferto_apikey: '748983ae-2195-457a-a3c0-b778210c3114',
//   transferto_apisecret: '01fe526b-9573-4a41-9380-a98a51d25b60',
//   baseCMXURL: 'http://52.163.83.160:7779/cmxAPI/',
//   countryUrl: 'https://api.ipdata.co/?api-key=4dfedc2445a6a1ad370aa138410098bfe3b46b9bed0b1ed40fc9a8cf',
//   accessTokenUrl: 'http://52.163.83.160:7778/cmxAPI/firebase/getAccessToken',
//   versionCheckUrl: 'http://52.163.83.160:7778/cmxAPI/firebase/getLiveVersion',
//   notificationUrl: 'https://fcm.googleapis.com/v1/projects/fishcoinuat/messages:send',
// };
