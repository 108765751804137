import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private socialSharing: SocialSharing) { }



  sharemfish() {
    this.socialSharing.share("Hey, You can install mFish app from Play store:" + " https://play.google.com/store/apps/details?id=com.eachmile.fishcoin&hl=en" + " and App store:" + " https://apps.apple.com/gb/app/mfish/id1488081510 " + "also you can log on using the link :" + " https://mfish-trace.co/").then(() => {
    }).catch((err) => {
     // console.log(JSON.stringify(err));
    });

  }
}
