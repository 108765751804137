import { Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { ControllersService } from '../services/ionic-common/controllers.service';
import { environment } from '../../environments/environment.prod';
//import { LoaderService } from '../services/loader/loader.service';
import { Network } from '@ionic-native/network/ngx';

import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { isUndefined } from "ionic-angular/util/util";
import { TransactionModel } from "../models/transaction.model";
import { BlockchainInputModel, TransferMarchantModel } from "../models/fishcoinBlockchain.model";
import { LoginModel } from "../models/session/login/login.model";
//import { auth } from "firebase/app";
import { Storage } from '@ionic/storage';
import { AESEncDecService } from "../services/aesenc-dec.service";
import { TransactionLogService } from '../services/transaction-log.service';
import { TransactionLogModel } from "../models/transationLog.model";
import { DatePipe } from '@angular/common';
//import moment from 'moment';
// import { ApiTransferTo } from '../services/api.transferto.service'
import { ApiTransferTo } from '../services/apitransferto.service';
//import { LoaderService } from "./loader/loader.service";
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'; //, retry
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseBlockchainURL: String = environment.baseBlockchainURL;
  stellarURL: String = environment.stellarURL;
  transferToURL: String = environment.transferToURL;
  transferto_apikey: String = environment.transferto_apikey;
  transferto_apisecret: String = environment.transferto_apisecret;
  accessTokenUrl:string = environment.accessTokenUrl;
  senNotificationUrl: string = environment.notificationUrl;
  versionCheckUrl:string = environment.versionCheckUrl;
  countryUrl = environment.countryUrl;
  localUrl = environment.localUrl;
  userData: any;
  data: any;
  blockchainInputModel: BlockchainInputModel = {};
  receiverData: any;
  senderData: any;
  transferMarchantModel: TransferMarchantModel = {};
  transactionLogModel: TransactionLogModel = {};
  constructor(private db: AngularFireDatabase, public network: Network,
    public controllerService: ControllersService, public transactionLogService: TransactionLogService,
    public aesEncDecService: AESEncDecService, private datePipe: DatePipe,
    public apiTransferTo: ApiTransferTo, private httpc: HttpClient, private storage:Storage,private iab:InAppBrowser
    ) {//private iab:InAppBrowser
     // console.log(environment);
    // network.onDisconnect().subscribe(() => {
    //   toast.show('you are offline');
    // });
    // //alert(ENV.baseBlockchainURL) ;
    // network.onConnect().subscribe(() => {
    //   //toast.show('you are online');
    // });
  }
  // http://52.163.83.160:7778/cmxAPI/firebase/getAccessToken 
  // http://192.168.9.48:7778/cmxAPI/firebase/getAccessToken LOCAL
  
  getAccessToken() {
    // console.log(this.accessTokenUrl);
    return this.httpc.get(this.accessTokenUrl).pipe(catchError(this.handleError));
  }

  sendNotification(data, token) {
    // let notData = {
    //   "validateOnly": "false",
    //   "message": {
    //     "token": data.FcmToken,
    //     "notification": {
    //       "title": data.title,
    //       "body": data.body,
    //     },
    //   }
    // }
    let notData = {
      "validateOnly": "false",
      "message": {
        "token": data.FcmToken,
        "notification": {
          "title": data.title,
          "body": data.body,
        },
        "apns": {
          "headers": {
            "apns-priority": "10",
          },
          "payload": {
            "aps": {
              "badge": 1,
              "sound": "default"
            },
            "mutable_content": true,
            "content_available": true,
          }
        }
      },
    }
    // console.log(notData);
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', token);
    return this.httpc.post(this.senNotificationUrl, notData, { headers: headers });
  }

  CheckNetwork(): Promise<any> {
    var connectedRef = this.db.database.ref(".info/connected");
    return new Promise((resolve, reject) => {
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  buyFischcoinAssets(apiName, datars): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postDataOnBlockchain("transferFishAssetsFromMerchant", datars).subscribe(res => {
        resolve(res);
      },e => {
       // console.log(e);
      })
    });
  }

  postDataOnBlockchain(apiName, datars){
    // console.log('inside postDataOnBlockchain'+JSON.stringify(apiName));
    // console.log('inside postDataOnBlockchain'+JSON.stringify(datars));
    let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*').set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
      .set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept').set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
      // console.log('api called',this.baseBlockchainURL + apiName);
      // console.log('headers passed ', headers);
      
      return this.httpc.post(this.baseBlockchainURL + apiName, datars, { headers: headers }).pipe(catchError(this.handleError));
  }

  // Get user details by user id
  getCurrentUserAccotDetailsByUId(userID: string): Promise<any> {
    //console.log('getCurrentUserAccotDetailsByUId(userID: string)'+userID);
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('UserID').equalTo(userID))).snapshotChanges().subscribe(data => {
          data.forEach(usr => {
            resolve(usr.payload.val());
          });
        })
    });
  }

  // Get user details by mobile number //not required
  getUserAcctDetailsByMoNum(mobileNumber): Promise<any> {
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('MobileNumber').equalTo(mobileNumber))).snapshotChanges().subscribe(data => {
          data.forEach(usr => {
            resolve(usr.payload.val());
          })
        })
    });
  }

  getUserAcctDetails(loginId): Promise<any> {
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('LoginId').equalTo(loginId))).snapshotChanges().subscribe(data => {
          data.forEach(usr => {
            resolve(usr.payload.val());
          })
        })
    });
  }

  // Create user account in ipfs and stellar
  CreateUserWallet(): Promise<any> {
    var uid = this.db.database.app.auth().currentUser.uid;
   // console.log('user id inside user wallet:' + uid);
    return new Promise((resolve, reject) => {
      this.postDataOnBlockchain("createNewAccount", { "userID": uid }).subscribe(res => {
       // console.log('inside wallet service ',res);
        resolve(res);
      },e => {
        // if (this.loader.loading != undefined) {
        //   this.loader.hideLoader();
        // }
       // console.log('161 ',e);
      });
    });
  }

  // Write data in block chain
  WriteDataOnBlockchain(txnModel: TransactionModel): Promise<any> {
    //console.log(txnModel);
    // alert('txnModel' + JSON.stringify(txnModel.key));
    return new Promise((resolve, reject) => {
      this.blockchainInputModel.jsonData = JSON.stringify(txnModel);
      // get publickey and secretkey of destination user
      this.getCurrentUserAccotDetailsByUId(txnModel.SentUId).then(cuser => {
        this.blockchainInputModel.destAcct = cuser.PublicKey;
        this.blockchainInputModel.destSeed = cuser.SecretKey;
        this.blockchainInputModel.destUserID = cuser.UserID;
        //get publickey and secretkey of source user.
        this.getCurrentUserAccotDetailsByUId(txnModel.RecdUId).then((duser:any) => {
          this.blockchainInputModel.srcAcct = duser.PublicKey;
          this.blockchainInputModel.srcSeed = duser.SecretKey;
          this.blockchainInputModel.srcUserID = duser.UserID;
          this.blockchainInputModel.amount = txnModel.FishcoinAssets;
          this.postDataOnBlockchain("writeDataOnBlockchain", this.blockchainInputModel).subscribe((res:any) => {
            this.transactionLogModel.srcUserID = this.blockchainInputModel.srcUserID;
            this.transactionLogModel.destUserID = this.blockchainInputModel.destUserID;
            if (res !== undefined && res.result !== undefined) {
              this.transactionLogModel.stellarHash = res.result[0].stellarTransactionHash;
              this.transactionLogModel.ipfsHash = res.result[0].ipfsHash;
            }
            this.transactionLogModel.paymentType = "Accept Record";
            this.transactionLogModel.recTransactionId = txnModel.key;
            this.transactionLogModel.createdBy = cuser.UserID;
            //this.transactionLogModel.createdDate = new Date().toString();
            this.transactionLogModel.createdDate = this.datePipe.transform(new Date().toString(), "yyy-MM-dd HH:mm:ss");
            // Log Transaction
            // this.transactionLogService.AddTransactionLog(this.transactionLogModel).then(transLogRes=>
            // {
            var transferModel: TransferMarchantModel = {};
            this.apiTransferTo.getMarginAmount().then(margAmt => {
              transferModel.srcAcct = duser.PublicKey;
              transferModel.srcSeed = duser.SecretKey;
              transferModel.srcUserID = duser.UserID;
              transferModel.amount = ((parseFloat(this.blockchainInputModel.amount) * (margAmt / 100)).toFixed(4)).toString();
              transferModel.feesAmount = ((parseFloat(this.blockchainInputModel.amount) * (margAmt / 100)).toFixed(4)).toString();
              transferModel.burnAmount = '0';
              this.transactionLogModel.pltMargin = transferModel.amount;
              this.transactionLogModel.fishcoin = (parseFloat(this.blockchainInputModel.amount)).toFixed(4).toString();
              // Transfer margin amount to merchant
              this.transferFishcoinToMerchant(transferModel).then(tranDetails => {
                // this.transactionLogModel.srcUserID = transferModel.srcUserID;
                // this.transactionLogModel.destUserID = "MerchantId";   
                // this.transactionLogModel.fishcoin =  transferModel.amount;
                // this.transactionLogModel.paymentType = "Platform Margin"  
                // this.transactionLogModel. createdBy = transferModel.srcUserID;
                // this.transactionLogModel.createdDate = this.datePipe.transform( new Date().toString(),"yyyy-MM-dd HH:mm:ss");
                // Log Transaction
                this.transactionLogService.AddTransactionLog(this.transactionLogModel).then(meTransLogRes => {
                  resolve(res);
                });

              }).catch(err => {
              //  console.log(JSON.stringify(err, Object.getOwnPropertyNames(err)));
              })
            }).catch(err => {
             // console.log(JSON.stringify(err));
            })
            // });
          },e => {
            // 
            //console.log(e);
          });
        }).catch(err => {
        //  console.log(JSON.stringify(err));
        })
      }).catch(err => {
        //console.log(JSON.stringify(err));
      })
    });
  }

  // Write data in block chain
  isBalanceAvailable(publicKey: string): Promise<any> {
    //console.log(publicKey);
    return new Promise((resolve, reject) => {
      var userDetails = {
        "accountId": publicKey
      };

      this.postDataOnBlockchain("accountDetails", userDetails).subscribe(res => {
        resolve(res);
      
      },e => {
        //console.log(e);
      });
    });
  }

  viewStellarTransDetails(stellarTrnHash: string) {
    const browser = this.iab.create(this.stellarURL + stellarTrnHash, '_blank', 'closebuttoncaption=Back');
  }

  // Post data block chain
  postDataOnTransferTo(apiName, datars): Promise<any> {
     return new Promise((resolve, reject) => {
    //   let headers = new HttpHeaders();
    //   headers.append('Access-Control-Allow-Origin', '*');
    //   headers.append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS');
    //   headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    //   headers.append('Accept', 'application/json');
    //   headers.append('Content-Type', 'application/json');
    //   this.httpc.post(this.transferToURL + apiName, datars, { headers: headers }).subscribe(res => {
    //     console.log(res);
    //     this.userData = res;
    //     if (!isUndefined(this.userData)) {
    //       if (this.userData !== undefined) {
    //         this.data = {
    //           flag: true,
    //           result: this.userData
    //         }
    //         resolve(this.data)
    //       } else {
    //         this.data = {
    //           flag: false,
    //           result: this.userData
    //         }
    //         resolve(this.data)
    //       }
    //     }
    //   });
    });
  }

  getPlans(countryId: string, mobileNumber: string) {
    return new Promise((resolve, reject) => {
      var userDetails = {
        "countryId": countryId,
        "mobileNumber": mobileNumber
      }
      this.postDataOnTransferTo("getPlans", userDetails).then(res => {
        resolve(res);
      }).catch(e => {
        
      //  console.log(e);
      });
    });
  }

  transferFishcoinOnMerchant(transferMarchantModel: TransferMarchantModel): Promise<any> {
    return new Promise((resolve, reject) => {
      // get publickey and secretkey of destnation user
      this.postDataOnBlockchain("transferFishAssetsFromMerchant", transferMarchantModel).subscribe(res => {
        resolve(res);
      },e => {
        //console.log(e);
        
      });
    });
  }

  transferFishcoinToMerchant(transferMarchantModel: TransferMarchantModel): Promise<any> {
    return new Promise((resolve, reject) => {
      // get publickey and secretkey of destnation user
      this.postDataOnBlockchain("transferFishAssetsToMerchant", transferMarchantModel).subscribe(res => {
        resolve(res);
      },e => {
       // console.log(e);
      });
    }).catch(err => {
     // console.log(JSON.stringify(err));
    });
  }

  getAppLocation() {
    return this.httpc.get(this.countryUrl).pipe(catchError(this.handleError));
  }

  getAppVersion(platfrm){
   // console.log(platfrm);
    return this.httpc.post(this.versionCheckUrl,platfrm).pipe(catchError(this.handleError));
  }

  getMethod(endPoint){
    return this.httpc.get(this.localUrl+endPoint);
  }

  getCountryMethod(endPoint){
    return new Promise((resolve, reject) => {
      return this.httpc.get(this.localUrl+endPoint).subscribe((res:any)=>{
        
          resolve(res);
        
        
      });
    });
    
  }

  postMethod(endPoint, params){
    return this.httpc.post(this.localUrl+endPoint,params);
  }
    

  //ERROR HANDLING FOR HTTP CLIENT METHODS
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const err = error || '';
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    //console.error(errMsg);
    return throwError(errMsg);
  }
}
