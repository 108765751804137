import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AESEncDecService {
  password = "0nc)p0!!er";
  algorithm = 'aes-256-cfb';
  
  constructor() { }

  encryptText(text) {
    return CryptoJS.AES.encrypt(text, this.password);
}

decryptText(text) {
    let bytes = CryptoJS.AES.decrypt(text, this.password); 
    return bytes.toString(CryptoJS.enc.Utf8);
}
}
