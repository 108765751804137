import { Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { LoginModel, UserModel } from "./../../models/session/login/login.model";
import { AESEncDecService } from "../aesenc-dec.service"
import { Observable } from "rxjs";
import { AngularFireAuth } from '@angular/fire/auth';
//import { isNumber } from "ionic-angular/umd/util/util";
import firebase from 'firebase';
import { ApiService } from "../api.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userListRef = this.db.list<LoginModel>('/MstUsers');
  private usrlist: Observable<any[]>;
  private loginModel: LoginModel;

  UserArray: Array<{ PublicKey: string, SecretKey: string, UserID: string }> = [];
  constructor(private db: AngularFireDatabase, private aesEncDecService: AESEncDecService,
    private apiService: ApiService, private datePipe: DatePipe, private afAuth: AngularFireAuth) {
  }

  // addAppVersion(data) {
  //   return this.db.app.database().ref('/AppVersion').set(data);
  // }

  getAppVersion() {
    return this.db.list('/AppVersion');
  }

  // getAppVersion(){
  //   console.log('getAppVersion');
  //   return new Promise((resolve, reject)=>{
  //     const version = this.db.list('/AppVersion').snapshotChanges();
  //     console.log(version);
  //     resolve(version);
  //   }).catch(err=>{
  //     console.log(err);
  //   });
  // }

  // Check if mobile number is already exist.
  UserIsExist(loginId: string): Promise<any> {
    //console.log('loginId'+JSON.stringify(loginId));
    return new Promise((resolve, reject) => {
      this.userListRef = this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(loginId.toLowerCase())));
      this.usrlist = this.userListRef.snapshotChanges();
      this.usrlist.subscribe(data => {
        //console.log("usrlist" + JSON.stringify(data));
      });
      this.usrlist.forEach(usr => {
        if (usr.length > 0) {
          resolve(true);
        }
        else {
          resolve(false);
        }
      }).catch(e => { reject(e) });
    })
  }

  UserDetailsById(id: string) {
    return this.db.list<LoginModel>('/MstUsers', ref => (
      ref.orderByChild('LoginId').equalTo(id)
    ));
  }
  // Get user details by mobile number
  UserDetailsByMobileNumber(mobileNumber: string) {
    return this.db.list<LoginModel>('/MstUsers', ref => (
      ref.orderByChild('MobileNumber').equalTo(mobileNumber)
    ));
  }

  // Get user details by mobile number
  UserDataByMobileNumber(mobileNumber: string): Promise<any> {
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('UserID').equalTo(mobileNumber)
      )).snapshotChanges().subscribe(data => {
        data.forEach(usr => {
          resolve(usr.payload.val());
        })
      })
    });
  }

  //userDetails by mobile or email
  getUserDetails(val: string) {
    //if (arg == 'phone') {
    return this.db.list<UserModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(val)))
    // } else if (arg == 'email') {
    //  return this.db.list<UserModel>('/MstUsers', ref => (ref.orderByChild('Email').equalTo(val)))
    //}
  }

  // Add user details
  AddUser(login: LoginModel) {
    // console.log('AddUser Service');
    //Encrypt Password
    //login.Password = this.aesEncDecService.encryptText(login.Password.toString()).toString();
    return this.db.object('MstUsers/' + login.UserID).set(login);
    //return this.userListRef.push(login)
  }
  addCountryData() {

  }
  // Edit user
  editUser(login: LoginModel) {
    // console.log(login);
    //Encrypt Password
    //login.Password = (this.aesEncDecService.encryptText(login.Password.toString())).toString();
    return this.userListRef.update(login.UserID, login);
  }

  // Edit user after wallet creation
  editUserAfterWallet(login: LoginModel) {
    //console.log('inside ' + JSON.stringify(login));
    return this.userListRef.update(login.UserID, login);
  }

  // Login method
  loginMethodOld(lgUserName: string, password: string, countrycode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Check LoginId is email or mobile number
      if (!isNaN(parseInt(lgUserName))) {
        this.userListRef = this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(countrycode + lgUserName)));
      }
      else {
        this.userListRef = this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(lgUserName.toString())));
      }
      this.usrlist = this.userListRef.snapshotChanges();
      this.usrlist.forEach
        (usr => {
          if (usr.length > 0) {
            usr.forEach(usrnew => {
              //Decrypt Password
              var pwd = usrnew.payload.val().Password.toString();
              var pwdDec = this.aesEncDecService.decryptText(pwd);
              //// Filter with Mobile Number
              if (!isNaN(parseInt(lgUserName))) {
                if (pwdDec.toString() == password && usrnew.payload.val().DialCode.toString() == countrycode && usrnew.payload.val().IsVerified == true) {
                  resolve(usrnew.payload.val());
                }
                else {
                  resolve(null);
                }
              }
              else  //// Filter with Email
              {
                if (pwdDec.toString() == password && usrnew.payload.val().DialCode.toString() == countrycode && usrnew.payload.val().IsVerified == true) {
                  resolve(usrnew.payload.val());
                }
                else if ((pwdDec.toString() == password) && (usrnew.payload.val().DialCode.toString() == countrycode)) {
                  this.afAuth.signInWithEmailAndPassword(lgUserName, password).then((emailVer: any) => {
                    if (emailVer.emailVerified == true) {
                      let verUser = {
                        userDetails: usrnew.payload.val(),
                        emailverified: "eVerTrue"
                      }
                      resolve(verUser);
                    }
                    else {
                      resolve("eVerFalse");
                    }
                  }).catch(e => { reject(e) });
                }
                else {
                  resolve(null);
                }
              }
            })
          }
          else {
            resolve(null);
          }
        }).catch(e => { reject(e) });
    });
  }

  // Login method

  loginMethod(lgUserName: string, password: string, countrycode: string): Promise<any> {
    // console.log('inside login service', countrycode);
    return new Promise((resolve, reject) => {
      var loginUser = (!isNaN(parseInt(lgUserName)) ? countrycode + lgUserName + '@fishcoin.com' : lgUserName)
      // console.log('login user' + loginUser);
      firebase.auth().signInWithEmailAndPassword(loginUser, password).then((emailVer: any) => {
        var authUser = emailVer;
        //  console.log('lgUserName ', lgUserName);
        if (!isNaN(parseInt(lgUserName))) {
          // console.log('inside if');
          this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(countrycode + lgUserName)))
            .valueChanges().subscribe((res: any) => {
              // console.log('inside if 185' + JSON.stringify(res));
              resolve({ result: 'success', isVerified: true, User: res[0] });
            }, err => {
              resolve({ result: err, isVerified: false, User: null });
            });
        } else {
          //  console.log('inside else 191', emailVer.displayName);
          if (emailVer.user.displayName == 'mFish user' || emailVer.user.displayName == countrycode) {
            if (emailVer.user.emailVerified == true) {
              // console.log('inside if email verfied true');
              this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(lgUserName.toString().toLowerCase())))
                .valueChanges().subscribe(res => {
                  var usr = res[0];
                  // console.log('inside if 207' + JSON.stringify(res));
                  if (usr.IsVerified == true) {
                    resolve({ result: 'success', isVerified: true, User: usr });
                  }
                  else {
                    // console.log('inside else 212' + JSON.stringify(res));
                    this.apiService.CreateUserWallet().then(refAdd => {
                      // console.log('inside else apiService 203' + JSON.stringify(refAdd));
                      if (refAdd.Result != undefined) {
                        this.loginModel = {};
                        this.loginModel.PublicKey = refAdd.Result[0].publicKey;
                        this.loginModel.SecretKey = refAdd.Result[0].secretKey;
                        this.loginModel.IsVerified = true;
                        this.loginModel.Country = usr.Country;
                        this.loginModel.CreatedDate = usr.CreatedDate;
                        this.loginModel.DialCode = usr.DialCode;
                        this.loginModel.LoginId = usr.LoginId;
                        this.loginModel.IsVerified = true;
                        //this.loginModel.Password = pass;
                        this.loginModel.RoleName = usr.RoleName;
                        this.loginModel.UserID = usr.UserID;
                        this.editUserAfterWallet(this.loginModel).then(() => {
                          resolve({ result: 'success', isVerified: true, User: this.loginModel });
                        }).catch(err => {
                          resolve({ result: err, isVerified: false, User: null });
                        });
                      }
                      else {
                        resolve({ result: 'Invalid Username and Password', isVerified: false, User: null });
                      }
                    });
                  }

                }, err => {
                  //  console.log('user err', err);
                  resolve({ result: err, isVerified: false, User: null });
                });
            }
            else {
              resolve({ result: 'Invalid Username and Password', isVerified: false });
            }
          }
          else {
            // console.log('inside else ', emailVer.displayName);
            if (emailVer.user.displayName == null) {
              this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(lgUserName.toString().toLowerCase())))
                .valueChanges().subscribe(res => {
                  var usr = res[0];
                  var profileUser = { displayName: res[0].DialCode, photoURL: "" };
                  emailVer.updateProfile(profileUser).then(
                    res => {
                      // console.log('inside email update', res); 
                    }).catch(err => {
                      // console.log(err);
                    })
                }, err => {
                   //console.log(err); 
                  })
            }
            resolve({ result: 'Invalid Country', isVerified: false, User: null });
          }

        }
      }).catch(e => {
       // console.log('255 >>>>>>>>>', e);
        reject(e);
      });
    });
  }
  
  // User Is Exist
  userIsExistByCountryCode(loginID: string, countrycode: string): Promise<any> {
    // console.log(loginID.toLowerCase());
    return new Promise((resolve, reject) => {
      if (!isNaN(parseInt(loginID))) {
        this.userListRef = this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo(countrycode + loginID)));
      } else {
        this.userListRef = this.db.list<LoginModel>('/MstUsers', ref => (ref.orderByChild('LoginId').equalTo((loginID.toString().toLowerCase()))));
      }
      this.userListRef.snapshotChanges().subscribe(res => {
        if (res.length == 0) {
          resolve(false);
        } else {
          resolve(true);
        }
      }, e => {
        reject(e);
      });
    });
  }

  // User details by Country Code and Mobile Number.
  userDetailsByCntCodeMoNumber(mobileNumberWithDialCode: string): Promise<any> {
    this.UserArray = [];
    return new Promise((resolve, reject) => {
      this.db.list('/MstUsers').snapshotChanges().subscribe(datas => {
        datas.forEach((data: any) => {
          if ((data.payload.val().DialCode.toString() + data.payload.val().MobileNumber.toString()) == mobileNumberWithDialCode.trim()) {
            this.UserArray.push({
              PublicKey: data.payload.val().PublicKey.toString(),
              SecretKey: data.payload.val().SecretKey.toString(),
              UserID: data.payload.val().UserID.toString(),
            });
          }
        })

        if (this.UserArray != undefined && this.UserArray != null && this.UserArray.length > 0 && this.UserArray != []) {
          resolve(this.UserArray);
        }
        else {
          resolve(null);
        }
      });
    });
  }

  //New Code

  // Get user details by user id
  getUserDetailsByUserId(userId: string): Promise<any> {
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('UserID').equalTo(userId))).snapshotChanges().subscribe(data => {
          data.forEach(usr => {
            resolve(usr.payload.val());
          })
        })
    }).catch(e => {
     // console.log(e);
    });
  }

  // Get user details by user id
  getCurrentUserAccotDetailsByUId(userID: string): Promise<any> {
    return new Promise((resolve) => {
      this.db.list<LoginModel>('/MstUsers', ref => (
        ref.orderByChild('UserID').equalTo(userID))).snapshotChanges().subscribe(data => {
          data.forEach(usr => {
            resolve(usr.payload.val());
          })
        })
    });
  }
  getDetailsByUId(userID: string) {
    return this.db.object('/MstUsers/' + userID).valueChanges();
  }
}