import { Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { ControllersService } from '../services/ionic-common/controllers.service';
import { Network } from '@ionic-native/network/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { isUndefined } from "ionic-angular/util/util";
import { BlockchainInputModel, TransferMarchantModel } from "../models/fishcoinBlockchain.model";
import { AESEncDecService } from "../services/aesenc-dec.service";
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiTransferTo {
  baseURL:String = environment.baseCMXURL;
  stellarURL: String = environment.stellarURL;
  userData: any;
  data: any;
  blockchainInputModel: BlockchainInputModel = {};
  receiverData: any;
  senderData: any;
  marginAmount: string;

  constructor(private db: AngularFireDatabase, public network: Network
    , public controllerService: ControllersService, public http: HttpClient,
    public aesEncDecService: AESEncDecService) {
    // network.onDisconnect().subscribe(() => {
    //   toast.show('you are offline');
    // });
    // network.onConnect().subscribe(() => {
    //   //toast.show('you are online');
    // });
  }

  CheckNetwork(): Promise<any> {
    var connectedRef = this.db.database.ref(".info/connected");
    return new Promise((resolve, reject) => {
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  // Post data on transfer
  postDataOnTransfer(apiName, datars): Promise<any> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.baseURL + apiName, datars, { headers: headers }).subscribe(res => {
        this.userData = res
        //if (!isUndefined(this.userData)) {
          if (this.userData !== undefined) {
            this.data = {
              flag: true,
              result: this.userData.data.countries
            }
            resolve(this.data)
          } else {
            this.data = {
              flag: false,
              result: this.userData
            }
            resolve(this.data)
          }
        //}
      }, err => {
       // console.log('Post error' + JSON.stringify(err));
      });
    });
  }

  // Get plan by mobileNumber
  getPlans(mobileNumber: string) {
    return new Promise((resolve, reject) => {
      //this.loader.showLoader();
      var userDetails = {
        "mobileNumber": mobileNumber
      }
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.baseURL + 'transferto/getPlans', userDetails, { headers: headers }).subscribe(res => {
        this.userData = res;
        //if (!isUndefined(this.userData)) {
          if (this.userData.data !== undefined && this.userData.data.product_list !== undefined) {
            this.data = {
              flag: true,
              result: this.userData
            }
            //this.loader.hideLoader();
            resolve(this.data)
          } else {
            this.data = {
              flag: false,
              result: this.userData
            }
            //this.loader.hideLoader();
            resolve(this.data)
          }
        //}
      });
    });
  }

  // make Payment by mobileNumber and product
  makePayment(mobileNumber: string, product: string) {
    return new Promise((resolve, reject) => {
      //this.loader.showLoader();
      var paymentDetails = {
        "mobileNumber": mobileNumber,
        "product": product
      }
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.baseURL + 'transferto/makePayment', paymentDetails, { headers: headers }).subscribe(res => {
        this.userData = res;
        //if (!isUndefined(this.userData)) {
          if (this.userData !== undefined) {
            this.data = {
              flag: true,
              result: this.userData
            }
            //this.loader.hideLoader();
            resolve(this.data)
          } else {
            this.data = {
              flag: false,
              result: this.userData
            }
            //this.loader.hideLoader();
            resolve(this.data)
          }
        //}
      }, err => {
       // console.log(JSON.stringify(err));
      });
    });
  }

  // Get country list
  getCountryList() {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.get(this.baseURL + 'transferto/countryList', { headers: headers }).subscribe(res => {
        this.userData = res;
        //if (!isUndefined(this.userData)) {
          if (this.userData !== undefined) {
            this.data = {
              flag: true,
              result: this.userData.data.countries
            }
            resolve(this.data)
          } else {
            this.data = {
              flag: false,
              result: this.userData
            }
            resolve(this.data)
          }
        //}
      }, err => {
      //  console.log('get transferto/countryList ' + JSON.stringify(err));
      })
    });
  }

  // Get Margin Amount
  getMarginAmount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.list('/Settings').snapshotChanges().subscribe(datas => {
        datas.forEach((data:any) => {
          if (data.payload.val() != undefined && data.payload.val() != null) {
            this.marginAmount = data.payload.val().MarginAmount;
          }
        })
        resolve(this.marginAmount);
      }, err => {
       // console.log('dblist/settings' + JSON.stringify(err));
      })
    });
  }
}
