import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class ControllersService {
  loading; dtoast; dloader;
  public backSubj = new Subject<any>();

  constructor(private toastCtrl: ToastController, private loadCtrl: LoadingController) {

  }

  async show(message: string, duration: number = 3000) {
    this.dtoast = this.toastCtrl.create({
      message,
      duration,
    });
    return (await this.dtoast).present();
  }

  async showLoading() {
    this.loading = await this.loadCtrl.create({
      message: 'Please wait...',
      duration: 45000
    });
    await this.loading.present();
  }

  async showLoaderWithDuration(duration: number, message: string) {
    this.dloader = await this.loadCtrl.create({
      message: message,
      duration: duration
    });
    await this.dloader.present();
  }

  async showLoaderWithoutDuration() {
    this.loading = await this.loadCtrl.create({
      message:'Please wait...',
    });
    await  this.loading.present();
  }

  dismiss() {
    this.loading.dismiss();
  }

  async dynamicLoaderShow(isLoading: boolean) {
    if(isLoading){
      this.loading = await this.loadCtrl.create({
        message: 'Please wait...',
        duration: 2000
      });
      await this.loading.present();
    }else{
      this.loading.dismiss();
    }
    
  }

  // dynamicLoaderDismiss(isLoading: boolean) {
  //   if (isLoading) {
  //     this.loading.dismiss();
  //   }
  // }



  showLoader() {

    this.loadCtrl.create({
      message: 'Please wait...'
    }).then((res) => {
      res.present();
    });

  }


  hideLoader() {

    this.loadCtrl.dismiss().then((res) => {
     // console.log('Loading dismissed!', res);
    }).catch((error) => {
      //console.log('error', error);
    });

  }
}
