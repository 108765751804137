import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { DtoneTransactionLog } from "../models/dtonelog.model";
// import { TransactionModel } from '../models/transaction.model'
//import { Observable } from "rxjs/Observable";
import { TransactionLogModel } from "../models/transationLog.model";

@Injectable({
  providedIn: 'root'
})
export class TransactionLogService {
  TxnList: AngularFireList<any>;
  DebitTxnList: AngularFireList<any>; 
  DtoneLog: AngularFireList<unknown>;
  constructor(private db: AngularFireDatabase) {
    this.TxnList = this.db.list('/TransactionLog');
    this.DtoneLog = this.db.list('/DtoneLogs');

    //console.log('TxnList :'+JSON.stringify(this.TxnList));
  }

  // Add Transaction Log
  AddTransactionLog(transactionLogModel: TransactionLogModel) {
    return this.TxnList.push(transactionLogModel);
    // console.log('inside AddTransactionLog'+JSON.stringify(transactionLogModel));
    // console.log('Txn List1'+JSON.stringify(this.TxnList));
    // this.TxnList.push(transactionLogModel);
    // console.log('Txn List'+JSON.stringify(this.TxnList));
    // return this.TxnList;
    // this.TxnList.push(transactionLogModel).then(res=>{
      
    //   // console.log('response inside transactionLog service'+JSON.stringify(res));
    // },err=>{
    //   // console.log('error inside transaction Log Service'+JSON.stringify(err));
    // })
  }

  AddTransactionLogs(transactionLogModel: TransactionLogModel) {
    return this.TxnList.push(transactionLogModel).key;
  }
  AddDtOneTxnLog(txnLog: DtoneTransactionLog){
    return this.DtoneLog.push(txnLog).key;
    }
  //Get Credit Transaction Log By UserID
  getCreditTxnLogListByUserID(userID: string) {
    return this.TxnList = this.db.list<TransactionLogModel>('/TransactionLog', ref => ref.orderByChild('destUserID').equalTo(userID.toString()));
  };

  //Get Debit Transaction Log By UserID
  getDebitTxnLogListByUserID(userID: string) {
    return this.DebitTxnList = this.db.list<TransactionLogModel>('/TransactionLog', ref => ref.orderByChild('srcUserID').equalTo(userID.toString()));
  }
}
