import { Component } from '@angular/core';
import { NavController, Platform, MenuController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { FIREBASE_CONFIG } from './firebase.credentials';
import { LoginModel } from './models/session/login/login.model';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { ControllersService } from './services/ionic-common/controllers.service';
import { LoginService } from './services/login/login.service';
import { Location } from '@angular/common';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SharedService } from './services/shared.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Profile', url: '/profile', icon: 'person' },
    { title: 'Buy Fishcoin', url: '/payment-gateway', icon: 'paper-plane' },
    { title: 'Transaction Log', url: '/transactionlog', icon: 'list' },
    { title: 'Invites', url: '/invites', icon: 'archive' }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  rootPage: any;
  android: boolean = false;
  userName: string = '';
  userImg: string = '';
  loginId: string = '';
  emailId: string = '';
  dialCode: string = '';
  activePage: any;
  isApp: boolean; playVersion;
  country; appLocation;
  loginModel: LoginModel = {};
  playData: any;
  loading;
  backOnRedeem: boolean;
  appVer: any;
  appVer1: any;
  otpPopUp: boolean = false;
  constructor(private sharedService: SharedService, private platform: Platform, private navCtrl: NavController, private storage: Storage, private loginService: LoginService,
    private socialSharing: SocialSharing, private _location: Location, private alertCtrl: AlertController,
    private firebaseMessaging: FirebaseMessaging, private controllerService: ControllersService, private menuCtrl: MenuController, private appVersion: AppVersion) {
    //this.platformRedirect();
    //console.log('inside constructor');
    this.storage.create();
    this.menuCtrl.swipeGesture(false);
    this.initializeApp();
    if (this.platform.is('cordova') || this.platform.is('android') || this.platform.is('ios')) {
      this.isApp = true;
    } else {
      this.isApp = false;
    }
    //console.log('Back press handler!', this._location.isCurrentPathEqualTo('/login'));

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this._location.isCurrentPathEqualTo('/tabs/transactions') || this._location.isCurrentPathEqualTo('/login')) {
        this.showExitConfirm();
        processNextHandler();
      } else if (this._location.isCurrentPathEqualTo('/tabs/redeem-new')) {
        this.backOnRedeem = true;
        this.controllerService.backSubj.next(this.backOnRedeem);
      } else {
        this._location.back();
      }

    });


  }


  async showExitConfirm() {
    //console.log('inside showExitConfirm');

    const alerts = await this.alertCtrl.create({
      header: 'Exit alert',
      message: 'Are you sure you want to exit the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          //console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
    //  .then(async alert => {


    if (!this.otpPopUp) {
      await alerts.present();
      this.otpPopUp=true;
    }
    // });
  }


  // PUSH NOTIFICATIONS END
  initializeApp() {
    //console.log('=====================', this.platform.is('android'));
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.checkCurrentAppVersion('android');
        //console.log('Its android');
      } else if (this.platform.is('ios')) {
        this.checkCurrentAppVersion('ios');
        //console.log('Its ios');
      }
      this.getStorage();
      this.initializePushNotification();
    }).catch(err => {
      //console.log(err);
    });
  }

  sharemfish() {
    this.sharedService.sharemfish();
  }

  getStorage() {
    this.storage.get('fishProfile').then((val) => {
      // //console.log(val);
      if (val != undefined && val != null && val != "") {
        this.loginId = val.loginId;
        this.userName = val.userName;
        this.userImg = val.imageUrl;
        this.dialCode = val.dialCode;
        if ((val.publicKey == undefined) || (val.secretKey == undefined)) {
          this.navCtrl.navigateRoot(['create-account']);
        }
        else if ((val.userName == val.loginId) || val.userName == undefined) {
          this.navCtrl.navigateRoot(['profile']);
        } else {
          //alert('inside else 103');
          this.storage.get('projectId').then(pid => {
            if (pid == FIREBASE_CONFIG.projectId) {
              this.navCtrl.navigateRoot(['tabs']);

            } else {
              this.storage.clear();
              this.storage.set('projectId', FIREBASE_CONFIG.projectId);
              this.platformRedirect();
            }
          }).catch(err => {
            //console.log(err);
          });
        }
      } else {
        this.platformRedirect();
      }
    }).catch((err) => {
      //console.log(err);
      //this.nav.setRoot(IntroPage);
    });
  }
  platformRedirect() {
    //console.log('isnide platform redirect');
    if (this.platform.is('desktop') || this.platform.is('mobileweb') || this.platform.is('android') || this.platform.is('ios')) {
      // this.nav.setRoot('LoginPage', { appLocation: this.appLocation });
      this.navCtrl.navigateRoot(['/login']);
      // this.menu.enable(false);
    } else {
      // this.nav.setRoot('IntroPage', null);
      //console.log('inside intro');
      this.navCtrl.navigateRoot(['intro']);
      // this.menu.enable(false);
    }
  }


  initializePushNotification() {
    if ((this.platform.is('android')) || (this.platform.is('ios'))) {
      //console.log('PLATFORM IS ANDROID OR IOS ===> 161 ');
      this.firebaseMessaging.requestPermission().then(function () {
        //console.log("Push messaging is allowed");
      });

      this.firebaseMessaging.onBackgroundMessage().subscribe((payload) => {
        //console.log("New background FCM message: ", payload);
      });

      this.firebaseMessaging.onMessage().subscribe(data => {
        // //console.log(data);
        //console.log(JSON.stringify(data));
        if (data.wasTapped) {
        } else {
          //console.log('Received in foreground');
          if (this.platform.is('ios')) {
            this.controllerService.show(data.aps.alert.body);
          } else if (this.platform.is('android')) {
            //console.log('notification data ', data);
            this.controllerService.show(data.gcm.body);
          }
          // this.router.navigate([data.landing_page, data.price]);
        }
      }, err => {
        //console.log('180 NOTIFICATION INIT ERROR >>>>>');
        //console.log(err);
      });

      this.firebaseMessaging.onTokenRefresh().subscribe(token => {
        //console.log('token changed onTokenRefresh ===> 184');
        //console.log(token);
        this.storage.get('fishProfile').then(res => {
          //console.log(res);
          if (res != null) {
            let data = { userId: res.userId, token: token }
            this.updateFcmToken(data);
          }
        });
      }, err => {
        //console.log('onTokenRefresh err');
        //console.log(err);
      });

    } else {
      //console.log('platform is web ==> 200');
    }
  }

  updateFcmToken(data) {
    //console.log('UPDATE FCM TOKEN CALLED ===> ');
    this.loginModel.UserID = data.userId;
    this.loginModel.FcmToken = data.token;
    //console.log('loginModelValue APP COMPONENT ===> ' + this.loginModel);
    this.loginService.editUser(this.loginModel).then(res => {
      //console.log('fcm token updated APP COMPONENT ==>' + JSON.stringify(res));
    });
  }


  async checkCurrentAppVersion(platform) {

    console.log(platform, 'checkCurrentAppVersion(platform)');
    //this.controllerService.showLoaderWithoutDuration();
    this.appVersion.getVersionNumber().then(value => {
      //console.log(value, typeof (value), '=======>version');
      //   this.appVer1 = value;
      //this.loading.dismiss();
      this.loginService.getAppVersion().snapshotChanges().subscribe(res => {
        // //console.log('getAppVersion()');

        res.forEach((lst: any) => {
          const ver = lst.payload.val();
          //console.log(ver.type, typeof (ver.type));
          // console.log(ver.minVersion
          // > value, ver.minVersion
          // , value);
          if (ver.type == platform) {
            // console.log('(ver.type == platform)');
            if (ver.forceUpdate == true) {
              if (ver.minVersion
                <= value) {
                // console.log('No need for forceupdate');
              } else {
                this.navCtrl.navigateRoot(['/update-app']);
              }
            }
          }
        });
      }, err => {

        // this.controllerService.dismiss();
        //console.log('snapshotChanges(platform)');
        //console.log(err);
      });
    }).catch(err => {
      //  this.controllerService.dismiss();
      //
      //
      //  alert(err);
    });



  }

}
