// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
  
};
// export const ENV = {
//     production: false,
//     baseBlockchainURL: 'http://52.163.83.160:1990/',
//     stellarURL: 'https://stellarchain.io/tx/',
//     transferToURL: 'https://gs-api.dtone.com/v1.1/',
//     transferto_apikey: '748983ae-2195-457a-a3c0-b778210c3114',
//     transferto_apisecret: '01fe526b-9573-4a41-9380-a98a51d25b60',
//     baseCMXURL:'http://52.163.83.160:3000/cmxAPI/',
//     countryUrl:'https://api.ipdata.co/?api-key=4dfedc2445a6a1ad370aa138410098bfe3b46b9bed0b1ed40fc9a8cf',
//     accessTokenUrl:'http://52.163.83.160:3000/cmxAPI/firebase/getAccessToken',
//     versionCheckUrl:'http://52.163.83.160:3000/cmxAPI/firebase/getLiveVersion',
//     notificationUrl:'https://fcm.googleapis.com/v1/projects/fishcoin-ec1e9/messages:send',
//     appVersion : '1.0.8'
//   };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
