import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, 
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'add-species',
    loadChildren: () => import('./pages/add-species/add-species.module').then( m => m.AddSpeciesPageModule)
  },
  {
    path: 'addrecordfarmer',
    loadChildren: () => import('./pages/addrecordfarmer/addrecordfarmer.module').then( m => m.AddrecordfarmerPageModule)
  },
  {
    path: 'addrecordfisher',
    loadChildren: () => import('./pages/addrecordfisher/addrecordfisher.module').then( m => m.AddrecordfisherPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./pages/create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'crop-image',
    loadChildren: () => import('./pages/crop-image/crop-image.module').then( m => m.CropImagePageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'invites',
    loadChildren: () => import('./pages/invites/invites.module').then( m => m.InvitesPageModule)
  },
  {
    path: 'payment-gateway',
    loadChildren: () => import('./pages/payment-gateway/payment-gateway.module').then( m => m.PaymentGatewayPageModule)
  },
  {
    path: 'paypal',
    loadChildren: () => import('./pages/paypal/paypal.module').then( m => m.PaypalPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/qrcode/qrcode.module').then( m => m.QrcodePageModule)
  },
  {
    path: 'redeem',
    loadChildren: () => import('./pages/redeem/redeem.module').then( m => m.RedeemPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'selectlinkrecords',
    loadChildren: () => import('./pages/selectlinkrecords/selectlinkrecords.module').then( m => m.SelectlinkrecordsPageModule)
  },
  {
    path: 'send-record',
    loadChildren: () => import('./pages/send-record/send-record.module').then( m => m.SendRecordPageModule)
  },
  {
    path: 'sendlinkrecord',
    loadChildren: () => import('./pages/sendlinkrecord/sendlinkrecord.module').then( m => m.SendlinkrecordPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'transactionlog',
    loadChildren: () => import('./pages/transactionlog/transactionlog.module').then( m => m.TransactionlogPageModule)
  },
  {
    path: 'transferfishcoin',
    loadChildren: () => import('./pages/transferfishcoin/transferfishcoin.module').then( m => m.TransferfishcoinPageModule)
  },
  {
    path: 'update-app',
    loadChildren: () => import('./pages/update-app/update-app.module').then( m => m.UpdateAppPageModule)
  },
  {
    path: 'viewrecord',
    loadChildren: () => import('./pages/viewrecord/viewrecord.module').then( m => m.ViewrecordPageModule)
  },
  {
    path: 'viewrecordfarmer',
    loadChildren: () => import('./pages/viewrecordfarmer/viewrecordfarmer.module').then( m => m.ViewrecordfarmerPageModule)
  },
  {
    path: 'viewrecordfisher',
    loadChildren: () => import('./pages/viewrecordfisher/viewrecordfisher.module').then( m => m.ViewrecordfisherPageModule)
  },
  
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'redeem-new',
    loadChildren: () => import('./pages/redeem-new/redeem-new.module').then( m => m.RedeemNewPageModule)
  },
  {
    path: 'redeem-popup',
    loadChildren: () => import('./pages/redeem-popup/redeem-popup.module').then( m => m.RedeemPopupPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true,preloadingStrategy: PreloadAllModules  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
